import React from 'react'
import { Link } from 'gatsby'

import styles from './TestCard.module.css'

const TestCard = ({ data }) => (
  <Link to={data.fields.slug} className={styles.component}>
    <div className={styles.text}>
      <h2 className={styles.title}>{data.title}</h2>
      <p className={styles.description}>{data.description}</p>
    </div>
  </Link>
)

export default TestCard
