import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import * as Hero from '../components/Hero'
import Centered from '../components/Centered'
import TestCard from '../components/TestCard'

import styles from './join-us.module.css'

export const query = graphql`
  query JoinUsPage {
    allTest {
      edges {
        node {
          name
          title
          description
          fields {
            slug
          }
        }
      }
    }
  }
`

const JoinUsPage = ({ data: { allTest } }) => (
  <Layout>
    <div className={styles.component}>
      <Hero.Box>
        <Hero.Back />
        <Hero.Title>Join us</Hero.Title>
        <Hero.Lead className={styles.heroLead}>Pick a test and apply now!</Hero.Lead>
      </Hero.Box>
      <Centered>
        <div className={styles.cards}>
          {allTest.edges.map(({ node }, i) => (
            <div className={styles.card} key={i}>
              <TestCard data={node} />
            </div>
          ))}
        </div>
      </Centered>
    </div>
  </Layout>
)

export default JoinUsPage
